import { onAuthStateChanged } from "firebase/auth";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { createContext, useEffect, useState } from 'react';
import { sortByBools } from "../components/reusable/sortFunctions";
import { auth, db } from "../components/utils/firebase/firebase";

const DataContext = createContext({
    autoValues: [],
    workers: [],
    inputTasks: [],
    outputTasks: [],
    stats: [],
    currentUser: null,
    authReady: false,
})

export const DataContextProvider = ({ children }) => {
    const [workers, setWorkers] = useState([]);
    const [inputTasks, setInputTasks] = useState([])
    const [outputTasks, setOutPutTasks] = useState([])
    const [autoValues, setAutoValues] = useState([])
    const [stats, setStats] = useState([])
    const [currentUser, setCurrentUser] = useState(null)
    const [authReady, setAuthReady] = useState(false)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user ? user : null);
            setAuthReady(true);
        })

        return () => {
            unsubscribe()
        }

    }, [])

    //Listen for changes in the tasks
    useEffect(() => {
        if (currentUser != null) {
            const collectionRef = collection(db, "clients/speed/tasks");
            const unsub = onSnapshot(collectionRef, async (snapShot) => {
                // Divide the documents into one array each
                const { outputTasks, inputTasks, autoValues } = await handleTasks(snapShot.docs.map((doc) => (doc.data())))

                //Set the tasks
                setOutPutTasks(outputTasks);
                setInputTasks(inputTasks)
                setAutoValues(autoValues)
            });
            return () => unsub;
        }

    }, [currentUser])

    useEffect(() => {
        if (currentUser != null) {
            const q = query(collection(db, "clients", 'speed', 'workers'), orderBy("currentTask", "desc"));
            const unsub = onSnapshot(q, (querySnapshot) => {
                setWorkers(querySnapshot.docs.map((doc) => (
                    doc.data()
                )));
            });
            return () => unsub;
        }

    }, [currentUser])

    useEffect(() => {
        if (currentUser != null) {
            const q = query(collection(db, "clients", "speed", "stats"), orderBy("date", "desc"), limit(70));
            const unsub = onSnapshot(q, (querySnapshot) => {
                setStats(querySnapshot.docs.map((doc) => (
                    doc.data()
                )).sort((a, b) => new Date(a.date) - new Date(b.date)));
            });
            return () => unsub;
        }
    }, [currentUser])


    async function handleTasks(data) {
        const tempObject = {}
        const outPutTasks = []

        ////console.log('Refreshed data')
        // Read each document in the collection
        data.forEach((obj) => {

            //Filter out the output tasks
            if (obj.taskType) {
                outPutTasks.push(obj)
            }

            // Array of input values
            if (obj.input) {
                Object.assign(tempObject, { autoValues: obj })
            }

            //Filter out the Qs tasks
            if (Object.keys(obj).includes('Q17')) {
                const tempArr = Object.keys(obj).map(k => obj[k]).filter((obj) => {
                    return obj.taskId != undefined
                })

                const sortedArray = sortByBools(tempArr, 'priority', 'asc')
                Object.assign(tempObject, { inputTasks: sortedArray })
            }

        })

        //Assign the array tasks since they are two
        Object.assign(tempObject, { outputTasks: outPutTasks })
        return tempObject
    }

    const context = { workers, inputTasks, outputTasks, autoValues, stats, currentUser, authReady }

    return (
        <DataContext.Provider value={context} >
            {children}
        </DataContext.Provider>
    )
}

export default DataContext