import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import dynamic from "next/dynamic";
const getPerformance = () => dynamic(() => import("firebase/performance"), { ssr: false });


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC6K73Tixj86eRj8oEEmZe6HHZPJ0QgFF4",
  authDomain: "lageralgo.firebaseapp.com",
  projectId: "lageralgo",
  storageBucket: "lageralgo.appspot.com",
  messagingSenderId: "422393014801",
  appId: "1:422393014801:web:fee6e018608d4e9bc01fb0"
};

export const app = initializeApp(firebaseConfig);

if (typeof window !== 'undefined') {
  // Enable analytics. https://firebase.google.com/docs/analytics/get-started
  const perf = getPerformance(app);

  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);
}

//Sing in existing user
export async function loginUser(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    return {
      message: `Signed in as ${user.displayName}`,
      status: "success",
      user: user
    }
  } catch (error) {
    const errorMessage = error.message.toString();
    //console.log(errorMessage.toString());

    if (errorMessage.includes('temporarily disabled')) {
      return {
        message: "Too many attempts for this email try again in a while",
        status: "error",
        user: null
      }
    } else if (errorMessage.includes("(auth/wrong-password)")) {
      return {
        message: "Wrong password",
        status: "warning",
        user: null
      }
    } else {
      return {
        message: errorMessage,
        status: "error",
        user: null
      }
    }
  }
}

export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
export const functions = getFunctions(app, "europe-west1");

