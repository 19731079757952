import { signOut } from "firebase/auth";
import { useRouter } from 'next/router';
import React, { createContext, useState } from 'react';
import { auth } from '../components/utils/firebase/firebase';

const LanguageContext = createContext({
  language: 'eng',
  handlelanguage: () => { },
  logout: () => { },
  EmailAuth: () => { },
})

export const LanguageContextProvider = ({ children }) => {
  const history = useRouter();
  const [language, setlanguage] = useState('swe');

  const logout = async () => {
    await signOut(auth)
    sessionStorage.clear()

    return history.push('/')
  }

  const handlelanguage = (event, newlanguage) => {
    if (newlanguage != null) {
      setlanguage(newlanguage);
    }
  };

  const context = { language, handlelanguage, logout }

  return (
    <LanguageContext.Provider value={context} >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext